import "./notAllowed.css";

import { Box, Flex } from 'monday-ui-react-core'

import React from "react";

export default function NotAllowed() {
    return (
        <>
            <div className="background"></div>
            <div className="foreground">
                <Flex gap={Flex.gaps.MEDIUM} direction={Flex.directions.COLUMN} className="outer-container">
                    <div>
                        <img className="header" alt="logo" src={"/header.png"} />
                    </div>
                    <div>
                        <p className="heading">Welcome to <strong>Embed Anything</strong>!</p>
                    </div>
                    <div>
                        <Box
                            border="components-Box-Box-module__border--oFq69"
                            rounded="components-Box-Box-module__roundedMedium--x875c"
                            className="box-sized-not-allowed"
                        >
                            <div><img className="thumbnail" alt="logo" src={"/logo.png"} /> <strong>As a Viewer, you are unable to use this app.</strong></div>
                        </Box>
                    </div>
                </Flex>
            </div>
        </>
    );
}
