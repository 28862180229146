import "monday-ui-react-core/dist/main.css";
import "./App.css";

import React, { useEffect, useRef, useState } from "react";

import AppContext from "./store/app";
import { AttentionBox } from "monday-ui-react-core"
import EmptyURL from './components/EmptyURL';
import InvalidURL from './components/InvalidURL';
import Landing from './components/Landing';
import LoadingURL from './components/LoadingURL';
import NotAllowed from './components/NotAllowed';
import SettingsRequired from './components/SettingsRequired';
import UnsupportedURL from './components/UnsupportedURL';
import { checkTokens } from "./services/api";
import mondaySdk from "monday-sdk-js";

const monday = mondaySdk();
const initialStateSettings = { urlColumnId: "", height: "100%", width: "100%" }

export default function Main() {
    const [context, setContext] = useState({ theme: "light" });
    const [mondaySettings, setMondaySettings] = useState(initialStateSettings);
    const [localSettings, setLocalSettings] = useState(initialStateSettings); // do NOT change
    const [urlToRender, setUrlToRender] = useState("");
    const [allowed, setAllowed] = useState(true);
    const [authorized, setAuthorization] = useState(false);
    const [initialized, setInitialized] = useState(false);
    const [fetched, setFetched] = useState(false);

    const [state, dispatch] = React.useContext(AppContext);
    const stateRef = useRef(state);
    stateRef.current = state;

    function isEmpty(obj) {
        if (!obj) return true
        if ((obj === null)) return true
        if ((obj === undefined)) return true
        if (obj === "") return true
        if (Object.keys(obj).length === 0) return true
        return false
    }

    useEffect(() => {
        async function initializeStore() {
            const contextRes = monday.get("context");
            const settingsRes = monday.get("settings");
            const allResponse = await Promise.all([contextRes, settingsRes]);
            const contextResult = allResponse[0]?.data;
            const settingsResult = allResponse[1]?.data;
            setContext(contextResult);
            setMondaySettings(settingsResult);
            setLocalSettings(settingsResult);
            if (contextResult.user?.isViewOnly) {
                setAllowed(false)
                setInitialized(true)
                return
            }
            if (allowed && contextResult.account.id) {
                const apiTokens = await checkTokens();
                if (apiTokens.tokens.monday) setAuthorization(true);
                else setAuthorization(false);
                setInitialized(true);
            }
        }

        initializeStore();

        monday.listen(["settings"], (eventData) => {
            setMondaySettings(eventData.data);
        });
    }, []);

    useEffect(() => {
        if (initialized) {
            if (authorized) {
                try {
                    if (
                        (Object.keys(mondaySettings.urlColumnId)[0] && isEmpty(localSettings.urlColumnId)) ||
                        (Object.keys(mondaySettings.urlColumnId)[0] !== Object.keys(localSettings.urlColumnId)[0]) ||
                        (mondaySettings.height !== localSettings.height) ||
                        (mondaySettings.width !== localSettings.width)
                    ) {
                        setLocalSettings(mondaySettings);
                    }
                }
                catch {
                }
            }
        }
    }, [mondaySettings]);

    useEffect(() => {

        function isValidURL(string) {
            var res = string.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
            return (res !== null)
        };

        async function fetchItem() {
            if (authorized && context.itemId && !isEmpty(localSettings.urlColumnId)) {
                setFetched(false);
                monday.api(
                    `query { me { account { id } } items (ids:${context.itemId}) { board { id } column_values (ids: [${Object.keys(localSettings.urlColumnId)[0]}]) { id value } parent_item { id board { id } } } }`
                )
                    .then(async (itemResponse) => {
                        let url = (JSON.parse(itemResponse.data.items[0]?.column_values[0]?.value)?.url ||
                            itemResponse.data?.items[0]?.column_values[0]?.value) ?? "blank";
                        url = url.replace(/['"]+/g, '');
                        if (isValidURL(url) || url === "blank") setUrlToRender(url);
                        else setUrlToRender("invalid");
                        setFetched(true);
                    })
                    .catch((error) => {
                    });
            }
        }

        fetchItem();

    }, [localSettings, authorized])

    return (
        <div className="App">
            <div className="App flex center">
                {(initialized ?
                    (allowed ?
                        (authorized ?
                            (!isEmpty(localSettings.urlColumnId) ?
                                (fetched ?
                                    (urlToRender === "blank" ?
                                        <EmptyURL /> :
                                        (urlToRender === "invalid" ?
                                            <InvalidURL /> :
                                            <>
                                                <object id="embedanythingiframe"
                                                    title="Embed Anything"
                                                    width={localSettings.width || "100%"}
                                                    height={localSettings.height || "100%"}
                                                    data={urlToRender}
                                                >
                                                    <div className="App flex center">
                                                        <UnsupportedURL />
                                                    </div>
                                                </object>
                                            </>)
                                    ) :
                                    <LoadingURL />
                                ) :
                                <SettingsRequired />
                            ) :
                            <Landing />
                        ) :
                        <NotAllowed />
                    ) :
                    <>
                        <AttentionBox
                            title="Hello from Embed Anything!"
                            text="Please wait while we load the Embed Anything view..."
                            type="success"
                        />
                    </>
                )}
            </div>
            <br />
        </div>
    );
}
