import React from "react";
import "./loadingURL.css";
import { AttentionBox } from 'monday-ui-react-core'

export default function LoadingURL() {
    return (
        <>
            <div className="components-Box-__stories__-Box-stories-module__boxWrapper--FNDBW">
                <AttentionBox title="Loading..." text="Your URL is loading..." type={AttentionBox.types.SUCCESS} className="monday-style-attention-box_box box-sized"/>
            </div>
        </>
    );
}
