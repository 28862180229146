import axios from "axios";
import mondaySdk from "monday-sdk-js";
import React from "react";
import "./landing.css";
import { AttentionBox, Box, Button, Flex } from 'monday-ui-react-core'
import config from '../../config'

const monday = mondaySdk();

export default function Landing() {
    const authorize = async () => {
        const user = await monday.api(`query { me { id account { name id slug } } }`);
        const context = await monday.get("context");
        const payload = {
            accountId: user.data.me.account.id,
            userId: user.data.me.id,
            backToUrl: `https://${user.data.me.account.slug}.monday.com/boards/${context.data.boardId}/pulses/${context.data.itemId}`,
        }
        const token = await axios.post(`${config.api_url}/signPayload`, payload).then(res => res.data.token)

        const url = `${config.api_url}/authorize?token=${token}`;
        window.open(url);
    };

    return (
        <>
            <div className="background"></div>
            <div className="foreground">
                <Flex gap={Flex.gaps.MEDIUM} direction={Flex.directions.COLUMN} className="outer-container">
                    <div>
                        <img className="header" alt="logo" src={"/header.png"} />
                    </div>
                    <div>
                        <Flex gap={Flex.gaps.NONE} direction={Flex.directions.COLUMN}>
                            <p className="heading">Welcome to <strong>Embed Anything</strong>!</p>
                            <p>To get started follow the steps below</p>
                        </Flex>
                    </div>
                    <div>
                        <Box
                            border="components-Box-Box-module__border--oFq69"
                            rounded="components-Box-Box-module__roundedMedium--x875c"
                            className="box-sized"
                        >
                            <Flex gap={Flex.gaps.XS} direction={Flex.directions.COLUMN} align={Flex.align.START}>
                                <Flex style={{ width: '100%' }} justify={Flex.justify.SPACE_BETWEEN}>
                                    <div><img className="thumbnail" alt="logo" src={"/logo.png"} /> <strong>Step 1: Authorize this view</strong></div>
                                    <div>
                                        <Button
                                            className="btn"
                                            onClick={authorize}
                                        >
                                            Authorize
                                        </Button>
                                    </div>
                                </Flex>
                            </Flex>
                        </Box>
                    </div>
                    <div>
                        <Box
                            border="components-Box-Box-module__border--oFq69"
                            rounded="components-Box-Box-module__roundedMedium--x875c"
                            className="box-sized"
                        >
                            <Flex gap={Flex.gaps.XS} direction={Flex.directions.COLUMN} align={Flex.align.START}>
                                <div><img className="thumbnail" alt="logo" src={"/logo.png"} /> <strong>Step 2: Assign a column</strong></div>
                                <div>To embed a URL, you need to assign a column. Click ⚙️ Settings in the upper right corner of the item, and select or add a column. (Link or Text Columns.)</div>
                            </Flex>
                        </Box>
                    </div>
                    <div>
                        <Box
                            border="components-Box-Box-module__border--oFq69"
                            rounded="components-Box-Box-module__roundedMedium--x875c"
                            className="box-sized"
                        >
                            <Flex gap={Flex.gaps.XS} direction={Flex.directions.COLUMN} align={Flex.align.START}>
                                <div><img className="thumbnail" alt="logo" src={"/logo.png"} /> <strong>Step 3: Start Embedding</strong></div>
                                <div>Once set up, paste your desired link in that column. Then navigate to the Embed Anything Item View. (You can set this as the default view.)</div>
                            </Flex>
                        </Box>
                    </div>
                    <div className="box-bottom">
                        <Flex gap={Flex.gaps.LARGE}>
                            <AttentionBox title="Need Support?" text="If you're having trouble with Embed Anything our support team can help! Visit support.carbonweb.co" type={AttentionBox.types.PRIMARY} className="monday-style-attention-box_box" />
                            <AttentionBox title="Is a URL not working?" text="View support.carbonweb.co to see if we have instructions for embedding your specific URL." type={AttentionBox.types.DANGER} className="monday-style-attention-box_box" />
                        </Flex>
                    </div>
                </Flex>
            </div>
        </>
    );
}
