import "./unsupportedURL.css";

import { AttentionBox, Button, Flex } from 'monday-ui-react-core'

import React from "react";

export default function UnsupportedURL() {
    const onHelp = () => {
        window.open('https://www.support.carbonweb.co/carbonto-do-embed-anything/#:~:text=%2B%20More-,Links%20we%20know%20work%20with%20Embed%20Anything%3A,-Non%2Dworking%20links', "_blank")
    }

    return (
        <>
            <Flex direction={Flex.directions.COLUMN} gap={Flex.gaps.MEDIUM}>
                <div>
                    <AttentionBox title="Unsupported URL" text="Security headers of the URL prevent it from being displayed here. View support.carbonweb.co to see if we have a workaround for your specific URL." type={AttentionBox.types.DANGER} className="box-sized" />
                </div>
                <Button onClick={onHelp}>
                    View Support
                </Button>
            </Flex>
        </>
    );
}
