import React from "react";
import "./settingsRequired.css";
import { AttentionBox, Flex } from 'monday-ui-react-core'

export default function SettingsRequired() {
    return (
        <>
            <Flex gap={Flex.gaps.LARGE} direction={Flex.directions.COLUMN}>
                <div className="components-Box-__stories__-Box-stories-module__boxWrapper--FNDBW">
                    <AttentionBox title="Assign a Column" text="Click ⚙️ Settings in the upper right corner of the item, and select or add a column. (Link or Text Columns supported.)" type={AttentionBox.types.DARK} className="monday-style-attention-box_box box-sized" />
                </div>
            </Flex>
        </>
    );
}
