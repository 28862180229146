import axios from "axios";
import mondaySdk from "monday-sdk-js";
import config from '../config'

const monday = mondaySdk();

let api = null;
let user = null;

export async function generateToken(data, backToUrl) {
  if (!user) {
    user = await monday.api(`query { me { id account { name id } } }`);
  }
  const payload = {
      accountId: user.data.me.account.id,
      userId: user.data.me.id,
      backToUrl,
      ...data
  }
  // call the API to sign this payload. TODO: See issue #1
  const res = await axios.post(`${config.api_url}/signPayload`, payload)
  return res.data.token
}

export async function createApi(data = null, backToUrl = 'https://monday.com') {
  const token = await generateToken(data, backToUrl)
  if (!api) {
    api = axios.create({
      baseURL: config.api_url
    });
    
    api.defaults.headers.common.Authorization = token;
    
    return api;
  
  } else {
    api.defaults.headers.common.Authorization = token;
  
    return api;
  }
}

export async function checkTokens() {
  const carbonApi = await createApi();
  const response = await carbonApi.get("tokens/check").then().catch();
  return response.data.data;
}

export async function checkBoard(boardId) {
  const carbonApi = await createApi({ boardId });
  const response = await carbonApi.get(`boards/check`).then().catch();
  return response.data.data;
}

export default api;
