import "./emptyURL.css";

import { AttentionBox, Button, Flex } from 'monday-ui-react-core'

import React from "react";

export default function EmptyURL() {
    const onHelp = () => {
        window.open('https://www.support.carbonweb.co/carbonto-do-embed-anything/#:~:text=%2B%20More-,Links%20we%20know%20work%20with%20Embed%20Anything%3A,-Non%2Dworking%20links', "_blank")
    }

    return (
        <>
            <Flex direction={Flex.directions.COLUMN} gap={Flex.gaps.MEDIUM}>
                <div>
                    <AttentionBox title="Empty URL" text="The URL column for this item contains a blank or invalid value. Double-check the placement of the pasted URL and that you are viewing the correct item." type={AttentionBox.types.PRIMARY} className="box-sized" />
                </div>
                <Button onClick={onHelp}>
                    View Support
                </Button>
            </Flex>
        </>
    );
}
