/** The application configuation */
const config = {
    api_url: process.env.REACT_APP_API_URL,
    app_name: process.env.REACT_APP_NAME,

    monday_app: {
        auth_url: 'https://auth.monday.com/oauth2/authorize',
        token_url: 'https://auth.monday.com/oauth2/token',
    }
}

export default config
